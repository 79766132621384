<template>
  <div>
    <div class="goods_review" v-if="_haveData">
      <van-cell>
        <div class="goods_review_title" slot="title">商品评论({{data.total}})</div>
				<div class="read_more tc" @click="$router.push({path: '/review', query: {goods_id: goods_id}})">
					<span>查看全部评价</span><van-icon name="arrow" />
				</div>
      </van-cell>
      <div class="review_box">
				<div class="review_item" v-for="item in data.data">
					<div class="r-top">
						<div class="user-info">
							<div class="user-avatar">
								<template v-if="!!item.avatar">
									<img :src="item.avatar" alt="">
								</template>
								<template v-else>
									<img src="@/assets/personal/avatar.png" alt="">
								</template>
							</div>
							<div class="f13 fc03">{{ item.nickname }}</div>
						</div>
						<div class="goods-rate">
							<van-rate v-model="item.rating" void-icon="star" allow-half readonly color="#F40337" />
						</div>
					</div>
					<div class="r-bottom">
						<div class="r-content">
							<span class="f14">{{ item.content }}</span>
						</div>
						<div class="r-images" v-if="item.image.length > 0">
							<template v-for="image in item.image">
								<div class="image_item" @click="_showPic(image)">
									<img :src="image" alt="">
								</div>
							</template>
						</div>
						<div class="r-goodsinfo">
							<span class="f13">{{ item.sku_title }}</span>
						</div>
					</div>
				</div>
			</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cell, Lazyload, Rate, ImagePreview, Icon } from 'vant'

Vue.use(Cell).use(Rate).use(Icon).use(Lazyload, {
  lazyComponent: true
})

export default {
  name: 'ReviewBar',
  props: {
    goods_id: '',
    data: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    _haveData () {
      if (Object.keys(this.data).length > 0) {
        if (this.data.data.length > 0) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    handler (component) {
      console.log(component)
    },
    _showPic (image) {
      ImagePreview([image])
			}
  }
}
</script>

<style scoped>
.goods_review_title {
  font-size: 14px;
  font-weight: bold;
  padding-left: 5px;
}

.review_box {
	padding: 0 15px;
  background: #fff;
}

.r-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.user-info {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.user-avatar {
	width: 40px;
	height: 40px;
	border-radius: 40px;
	overflow: hidden;
	margin-right: 10px;
}

.user-avatar img {
	width: 100%;
}

.r-content {
	margin-bottom: 10px;
	font-size:14px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.r-images {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-bottom: 10px;
}

.image_item {
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	overflow: hidden;
}

.image_item img {
	width: 100%;
}

.r-goodsinfo {
	color: #888;
}

.review_item {
	padding: 10px 0;
	border-bottom: 1px solid #ebedf0;
}

.read_more {
	color: #888;
	font-size: 13px;
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

</style>
