// 获取商品列表
export const getProductList = (params) => window._http('/Product-index', params)

// 获取高佣商品列表
export const getHightCommission = (params) => window._http('/Product-getHightCommission', params)

// 获取统计商品列表
export const getSettlementList = (params) => window._http('/Product-getSettlementList', params)

// 统计购物车价格
export const getSettlementListByCart = (params) => window._http('/Product-getSettlementListByCart', params)

// 获取推荐商品列表
export const getRecommendProducts = (params) => window._http('/Product-getRecommendProducts', params)

// 获取随机新人专享商品
export const getForNewUserRandom = (params) => window._http('/Product-forNewUserRandom', params)

// 获取商品item
// export const getProductDetail = (id, params) => window._http('/Product-detail/' + id, params)
export const getProductDetail = (params) => window._http('/Item-info', params)

// 获取商品详情
export const getDetailContent = (params) => window._http('/Product-detailContent', params)

// 获取商品详情
export const getShareGoods = (id, params) => window._http('/Product-share/' + id, params)
export const getShareItem = (params) => window._http('/Item-shareGoods', params)

// 榜单商品
export const getOptimusMaterial = (params) => window._http('/Product-getOptimusMaterial', params)

export const getGoodsThemeList = (params) => window._http('/Product-getGoodsThemeList', params)

export const getGoodsShareLink = (params) => window._http('/Item-share', params)
//获取商品购买链接、淘口令
export const getPromotionData = (params) => window._http('/Item-getPromotionData', params)