<template>
  <div>
    <div class="goods_detail" v-if="!!content || detail_images.length>0">
      <div class="goods_detail_title">
				<div class="title">商品详情</div>
			</div>
			<template v-if="detail_images.length>0">
				<div class="goods_content">
					<div class="goods_image" v-for="(i, index) in detail_images">
						<!-- <img :src="i" alt=""> -->
						<van-image width="100%" lazy-load :src="i">
							<template v-slot:error>加载失败</template>
						</van-image>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="goods_content" v-html="content"></div>
			</template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cell, Lazyload, Image } from 'vant'

Vue.use(Cell).use(Image).use(Lazyload, {
  lazyComponent: true
})

export default {
  name: 'GoodsContent',
  props: {
    content: {
      type: String,
      default: ''
		},
		detail_images: {
			type: Array,
			default: function() {
				return []
			}
		}
  },
  methods: {
    handler (component) {
			console.log(component)
		},	
		_loadFail(index) {
			console.log(index)
		}
  }
}
</script>

<style scoped>
.goods_detail_title {
  height: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
}
.goods_image {
	font-size: 0;
	width: 100%;
}
.goods_image img {
	width: 100%;
}
.title {
	font-family: 'PingFangSC-Medium';
	font-size: 14px;
	color: #4D4D4D;
	line-height: 8px;
	padding: 0 2px;
	border-bottom: 5px solid #FF9D9B;
}

.goods_content {
  padding: 0 10px;
  background: #fff;
	text-align: center;
}
.goods_content /deep/ img {
	font-size:0;
	vertical-align:bottom;
  max-width: 100%;
}
</style>
